"use client"

import { ALERT_CODES } from "~/lib/alert"
import { useAlerts } from "~/hooks/useAlerts"
import Auth0Buttons from "~/components/ui/Auth0Buttons"
import { AccountPanel } from "~/components/ui/Panels/AccountPanel"
import { content as contentStyle } from "~/components/ui/Panels/AccountPanel/style.css"
import RegisterPanel from "~/components/ui/Panels/Register"
import { SubFormMentions } from "~/components/ui/SubFormMentions"
import LoginFormTemplate from "~/components/forms/LoginForm"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

import * as css from "./style.css"

interface LoginPanelProps {
  privateSales?: boolean
}

function LoginPanel({ privateSales = false }: LoginPanelProps) {
  const { add, removeCurrent } = usePanel()
  const triggerAlert = useAlerts()

  const handleLoginSuccess = () => {
    removeCurrent()
    triggerAlert(ALERT_CODES.LOGGED_IN)
  }

  const t = useTranslate()
  const ctaProps = {
    children: t("account_register"),
    onClick: () => {
      removeCurrent()
      add(<RegisterPanel />)
    },
  }

  return (
    <AccountPanel headerTitle={t("account_log_in")}>
      <div className={contentStyle}>
        {privateSales && <div className={css.privateSales}>{t("account_log_in_private_sales")}</div>}
        <LoginFormTemplate onSuccess={handleLoginSuccess} />
        <Auth0Buttons />
        <SubFormMentions title={t("account_dont_have_account")} ctaProps={ctaProps} />
      </div>
    </AccountPanel>
  )
}

export default LoginPanel
