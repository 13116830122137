"use client"

import { useSearchParams } from "next/navigation"
import clsx from "clsx"

import { LoginForm } from "@unlikelystudio/react-ecommerce-hooks"

import useMutationErrors from "~/hooks/account/useMutationErrors"
import { useRedirectMultipassCheckoutUrl } from "~/hooks/useGetMultipassCheckoutUrl"
import { FORM_NAMES, FormTemplate } from "~/components/forms/FormTemplate"
import { useInternalRedirects } from "~/managers/InternalRedirectsManager/hooks"

import * as css from "./styles.css"

export interface LoginFormTemplateProps {
  className?: string
  onSuccess?: () => void
}

function LoginFormTemplate({ className, onSuccess }: LoginFormTemplateProps) {
  const onError = useMutationErrors()
  const [redirects] = useInternalRedirects()
  const { mutate: handleCartConfirmation } = useRedirectMultipassCheckoutUrl()
  const searchParams = useSearchParams()

  const isCheckoutUrlSearchParams = searchParams.get("checkout_url")

  return (
    <div className={clsx(css.LoginFormTemplate, className)}>
      <FormTemplate withRequiredText formName={FORM_NAMES.LOGIN}>
        <LoginForm
          forgotPasswordLink={redirects?.forgotPassword?.href}
          mutationOptions={{
            onSuccess: (data) => {
              onSuccess?.()
              if (isCheckoutUrlSearchParams) {
                handleCartConfirmation({
                  url: isCheckoutUrlSearchParams,
                  accessToken: data?.customerAccessToken?.accessToken,
                })
              }
            },
            onError,
          }}
        />
      </FormTemplate>
    </div>
  )
}

export default LoginFormTemplate
