import { useRef, type PropsWithChildren } from "react"

import PanelHeader from "~/components/ui/Panels/PanelHeader"
import { Panel } from "~/managers/PanelManager"

import { theme } from "~/styles/theme.css"

import * as css from "./style.css"

type AccountPanelProps = PropsWithChildren<{
  headerTitle?: string
}>

export function AccountPanel({ headerTitle, children }: AccountPanelProps) {
  const panelRef = useRef<HTMLDivElement>(null)
  return (
    <Panel className={css.wrapper} zIndex={60} clickOutsideRef={panelRef}>
      <style>{`body{--form-container-background: ${theme.colors["white"]};}`}</style>
      <div className={css.Panel} ref={panelRef}>
        <PanelHeader className={css.header} title={headerTitle} />
        {children}
      </div>
    </Panel>
  )
}
