import type { StorefrontError } from "@unlikelystudio/commerce-connector"

import type { AlertPresetKeys } from "~/lib/alert"
import useCustomerErrors from "~/hooks/account/useCustomerErrors"
import { useAlerts } from "~/hooks/useAlerts"

export type Errors = Record<string, AlertPresetKeys>

export default function useMutationErrors() {
  const onError = useCustomerErrors()
  const triggerAlert = useAlerts()

  return (error: StorefrontError) => {
    const { errsGlobals } = onError(error)

    const errors = Object.values(errsGlobals)
    if (errors?.length) {
      errors.map(triggerAlert)
    } else triggerAlert()
  }
}
