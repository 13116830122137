"use client"

import { useAtom } from "jotai"

import "~/managers/InternalRedirectsManager/ClientInternalRedirectsManager"

import { internalRedirectsAtom } from "~/managers/InternalRedirectsManager/store"

export function useInternalRedirects() {
  return useAtom(internalRedirectsAtom)
}
