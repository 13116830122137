import { getLang } from "~/lib/i18n/utils/get-i18n"
import useLocale from "~/hooks/useLocale"

function useLang(): string {
  const locale = useLocale()

  return getLang(locale)
}

export default useLang
