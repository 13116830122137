"use client"

import { useState } from "react"
import { useParams } from "next/navigation"
import clsx from "clsx"

import { formDictionary, RegisterForm } from "@unlikelystudio/react-ecommerce-hooks"

import { ALERT_CODES } from "~/lib/alert"
import { METAFIELD_INPUTS } from "~/lib/shopify/constants"
import useMutationErrors from "~/hooks/account/useMutationErrors"
import { useProcessNewCustomerTags } from "~/hooks/account/useProcessNewCustomerTags"
import useUpdateCustomerOnRegister from "~/hooks/account/useUpdateCustomerOnRegister"
import { FORM_NAMES, FormTemplate } from "~/components/forms/FormTemplate"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { useInternalRedirects } from "~/managers/InternalRedirectsManager/hooks"
import { objectKeys } from "~/utils/object-keys"

import * as css from "./styles.css"

export interface RegisterFormTemplateProps {
  className?: string

  onSuccess?(): void
  onLoginSuccess?(): void
}

function RegisterFormTemplate({ className, onSuccess, onLoginSuccess }: RegisterFormTemplateProps) {
  const [_mail, setMail] = useState<string | null>(null)
  const [redirects] = useInternalRedirects()
  const privacyPolicyHref = redirects?.legals?.href ?? "/"

  const t = useTranslate()
  const onError = useMutationErrors()
  const params = useParams()
  const metafields = [{ ...METAFIELD_INPUTS.CUSTOMER_GENDER, value: "Male" }]
  const tags = useProcessNewCustomerTags(params.locale)

  const { mutate: mutateCustomer, isLoading } = useUpdateCustomerOnRegister({
    onSuccess: () => {
      onSuccess?.()
      onLoginSuccess?.()
    },
    /**
     * Return onSuccess as the initial update customer was successful
     * If you reach this state
     */
    onError: () => {
      onSuccess?.()
      onLoginSuccess?.()
    },
  })

  const overridenDictionary = objectKeys(formDictionary).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: { ...formDictionary[curr], submitRegister: t("cta_validate") },
    }
  }, {} as typeof formDictionary)

  return (
    <div className={clsx(css.RegisterFormTemplate, className)}>
      <FormTemplate
        withRequiredText
        className={css.formTemplate}
        dictionary={overridenDictionary}
        formName={FORM_NAMES.REGISTER}
        isLoading={isLoading}
      >
        <RegisterForm
          hideFields={["birthdate", "gender"]}
          showDetailedActivateAccountError
          privacyPolicyLink={privacyPolicyHref}
          mutationOptions={{
            onSettled(_data, _error, variables) {
              setMail(variables.email)
            },
            onSuccess: (data) => {
              const id = data?.customer?.id
              if (id) {
              }
            },

            onError: (error) => {
              const isCustomerDisabled = error?.response?.errors?.find(
                (item) => item?.code === ALERT_CODES.CUSTOMER_DISABLED
              )
              if (!isCustomerDisabled) onError(error)
            },
          }}
          loginMutationOptions={{
            onSuccess: (data) => {
              if (data?.customerAccessToken?.accessToken) {
                mutateCustomer({ metafields, tags, customerAccessToken: data.customerAccessToken.accessToken })
              }
            },
          }}
        />
      </FormTemplate>
    </div>
  )
}

export default RegisterFormTemplate
