"use client"

import SquareCta from "~/components/ui/SquareCta"
import Icon from "~/components/abstracts/Icon"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export default function Auth0Buttons() {
  const t = useTranslate()

  return (
    <div className={css.Auth0Buttons}>
      <SquareCta href="/api/auth/login-with-google" className={css.SSOButton} fill>
        <Icon name={"AuthGoogle"} direction="right" className={css.icon} />
        <span>{t("account_login_with", { provider: "Google" })}</span>
      </SquareCta>

      <SquareCta href="/api/auth/login-with-facebook" className={css.SSOButton} fill>
        <Icon name={"AuthFacebook"} direction="right" className={css.icon} />
        <span>{t("account_login_with", { provider: "Facebook" })}</span>
      </SquareCta>
    </div>
  )
}
