import { useMutation, type UseMutationOptions } from "@tanstack/react-query"
import type { ErrorOption } from "react-hook-form"

import { SHOPIFY_CUSTOMER_UPDATE_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import type { MetafieldInput } from "~/lib/shopify/constants"
import useLocale from "~/hooks/useLocale"
import useOnMutationError from "~/hooks/useOnMutationError"

interface UseUpdateCustomerOnRegisterMutationParams {
  tags: string[]
  metafields: Omit<MetafieldInput, "ownerId">[]
  customerAccessToken: string
}

export default function useUpdateCustomerOnRegister(
  mutationOptions?: UseMutationOptions<any, any, UseUpdateCustomerOnRegisterMutationParams>,
  setError?: (name?: string, error?: ErrorOption) => void
) {
  const onMutationError = useOnMutationError(setError)
  const locale = useLocale()
  return useMutation<any, any, UseUpdateCustomerOnRegisterMutationParams>(
    async ({ tags, metafields, customerAccessToken }: UseUpdateCustomerOnRegisterMutationParams) => {
      return await requestNextApiEndpoint(SHOPIFY_CUSTOMER_UPDATE_PARAMS, {
        accessToken: customerAccessToken,
        payload: {
          locale,
          tags,
          metafields,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        mutationOptions?.onSuccess?.(data, ...rest)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    }
  )
}
