import type { Nullish } from "~/@types/generics"
import { getI18nKey } from "~/lib/i18n/utils/get-i18n"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"

export function useProcessNewCustomerTags(locale: Nullish<string | string[]>) {
  const lang = getI18nKey(getProcessedLocale(locale), "lang")
  const currency = getI18nKey(getProcessedLocale(locale), "currency")
  const processedLocale = Array.isArray(locale) ? locale[0] : locale
  const [market] = processedLocale?.split("-").reverse() ?? []
  const marketIsoCode = (market === "eu" ? "fr" : market)?.toUpperCase()

  return [`language:${lang}`, `currency:${currency}`, `market:${marketIsoCode}`]
}
