import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import InlineCta, { type InlineCtaProps } from "~/components/ui/InlineCta"

import * as css from "./styles.css"

export type SubFormMentionsProps = PropsWithClassName<{
  title: string
  ctaProps: InlineCtaProps
}>

function SubFormMentions({ className, title, ctaProps }: SubFormMentionsProps) {
  return (
    <div className={clsx(css.SubFormMentions, className)}>
      <span className={css.title}>{title}</span>
      <InlineCta className={css.cta} size="small" color="black" withDefaultLine withLine {...ctaProps} />
    </div>
  )
}

export { SubFormMentions }
