import { useMutation, type UseMutationOptions } from "@tanstack/react-query"
import type { ErrorOption } from "react-hook-form"

import { useCustomerContext, useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import type { Nullish } from "~/@types/generics"
import { SHOPIFY_MULTIPASS_PARAMS } from "~/lib/api/constants"
import { requestNextApiEndpoint } from "~/lib/api/utils/request-endpoint"
import useLang from "~/hooks/useLang"
import useOnMutationError from "~/hooks/useOnMutationError"

import { useGetCart } from "../useGetCart"

export interface getMultipassCheckoutUrlResponse {
  errors: { message: string }[]
  urlToRedirect: string
}

interface UseUseGetMultipassCheckoutUrlMutationParams {
  url: string
}

export function useGetMultipassCheckoutUrl(
  mutationOptions?: UseMutationOptions<
    getMultipassCheckoutUrlResponse | null,
    any,
    UseUseGetMultipassCheckoutUrlMutationParams
  >,
  setError?: (name?: string, error?: ErrorOption) => void
) {
  const { data: customer } = useGetCustomer()
  const { customerAccessToken } = useCustomerContext()
  const onMutationError = useOnMutationError(setError)
  const lang = useLang()

  return useMutation<getMultipassCheckoutUrlResponse | null, any, UseUseGetMultipassCheckoutUrlMutationParams>(
    async ({ url }) => {
      if (!url) return null
      if (!customer?.email || !customerAccessToken) {
        window.location.href = url
        return null
      }

      return await requestNextApiEndpoint(SHOPIFY_MULTIPASS_PARAMS, {
        accessToken: customerAccessToken,
        payload: {
          url: url,
          lang,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: async (data, ...rest) => {
        //! standardize error response
        const error = data?.errors?.[0]
        const errorPayload = { payload: [data?.errors?.[0]] }
        if (data?.urlToRedirect && customerAccessToken) {
          const url = data?.urlToRedirect
          window.location.href = url
        }

        if (error) {
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    }
  )
}

export function useRedirectMultipassCheckoutUrl(
  mutationOptions?: UseMutationOptions<
    getMultipassCheckoutUrlResponse | null,
    unknown,
    { url: Nullish<string>; accessToken: Nullish<string> }
  >,
  setError?: (name?: string, error?: ErrorOption) => void
) {
  const onMutationError = useOnMutationError(setError)
  const lang = useLang()
  const { cartPayload } = useGetCart()
  const checkoutWebUrl = cartPayload?.checkoutUrl ?? ""

  return useMutation<
    getMultipassCheckoutUrlResponse | null,
    unknown,
    { url: Nullish<string>; accessToken: Nullish<string> }
  >(
    async ({ url, accessToken }) => {
      if (!url || !accessToken) {
        window.location.href = checkoutWebUrl
        return null
      }

      return await requestNextApiEndpoint(SHOPIFY_MULTIPASS_PARAMS, {
        accessToken,
        payload: {
          url,
          lang,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: async (data, ...rest) => {
        //! standardize error response
        const error = data?.errors?.[0]
        const errorPayload = { payload: [data?.errors?.[0]] }

        if (data?.urlToRedirect) {
          const url = data?.urlToRedirect
          window.location.href = url
        }

        if (error) {
          return onMutationError(errorPayload, rest, mutationOptions?.onError)
        }
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    }
  )
}
